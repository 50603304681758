import React from "react"
import Layout from "../../layouts/default"

import Breadcrumb from "../../components/breadcrumb"
import Heading from "../../components/heading"
import PageTitle from "../../components/page-title"
import Paragraph from "../../components/paragraph"
import Seo from "../../components/seo"
import Stack from "../../components/stack"
import TextLink from "../../components/textlink"

const NeuigkeitenHoerl = () => {
  return (
    <Layout backdrop="aussen">
      <Seo
        title="Werkvertrag für kommende Sonderausstellung "
        description="Das Museum Hölderlinturm erarbeitet derzeit eine Sonderausstellung zur Hölderlin-Rezeption in der Zeit des Nationalsozialismus, die im Juni 2022 eröffnet werden soll. Für die eigenständige Arbeit an Recherchen, das Verfassen von Ausstellungstexten und das Lektorat der Texte für den Begleitkatalog zur Ausstellung suchen wir ab Oktober 2021 eine*n selbständig arbeitende*n Masterstudent*in oder Doktorand*in  aus dem Bereich Literaturwissenschaft, Geschichtswissenschaft oder einem vergleichbaren Studienfach."
      />
      <Stack space={[12, 24]}>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Mitarbeiten",
                link: "/mitarbeiten",
              },
              {
                title: "Werkvertrag für kommende Sonderausstellung ",
                link: "/mitarbeiten/werkvertrag-sonderaustellung",
              },
            ]}
          />
          <PageTitle>Werkvertrag für kommende Sonderausstellung</PageTitle>

          <Paragraph dropcap={true}>
            Das Museum Hölderlinturm erarbeitet derzeit eine Sonderausstellung
            zur Hölderlin-Rezeption in der Zeit des Nationalsozialismus, die im
            Juni 2022 eröffnet werden soll.
          </Paragraph>
          <Paragraph>
            Für die eigenständige Arbeit an Recherchen, das Verfassen von
            Ausstellungstexten und das Lektorat der Texte für den Begleitkatalog
            zur Ausstellung suchen wir ab Oktober 2021 eine*n selbständig
            arbeitende*n Masterstudent*in oder Doktorand*in aus dem Bereich
            Literaturwissenschaft, Geschichtswissenschaft oder einem
            vergleichbaren Studienfach.
          </Paragraph>
          <Paragraph>
            Die Sonderausstellung zur Hölderlin-Rezeption in der NS-Zeit
            entsteht in Kooperation mit der Hölderlin-Gesellschaft e.V. Sie
            setzt sich kritisch mit Hölderlins Vereinnahmung in der Zeit des
            Nationalsozialismus auseinander und reflektiert zugleich, inwiefern
            sich Hölderlin für eine solche Vereinnahmung angeboten hat. Dabei
            beleuchtet sie die Rezeption und Indienstnahme durch verschiedene
            Seiten – von der Frontlektüre der Soldaten bis hin zur Rezeption in
            den Lagern. Ein besonderer Fokus liegt auf den Aktivitäten in
            Tübingen und in Stuttgart, bei denen die Bedingungen der
            (Vor-)Kriegszeit zuweilen auch strategisch genutzt wurden, um
            nationale wissenschaftliche Großprojekte wie etwa die Stuttgarter
            Hölderlin-Ausgabe oder die Gründung einer literarischen
            Hölderlin-Gesellschaft zu verwirklichen.
          </Paragraph>
        </Stack>

        <Stack>
          <Heading as="h2" level={3}>
            Interesse?
          </Heading>
          <Paragraph>
            Wir freuen uns über eine Bewerbung mit Motivationsschreiben und
            Lebenslauf per Mail an:{" "}
            <TextLink href="mailto:hoelderlinturm@tuebingen.de">
              hoelderlinturm@tuebingen.de
            </TextLink>
          </Paragraph>
          <Paragraph>
            Ansprechpartner: Florian Mittelhammer (Leitender Kurator)
          </Paragraph>
        </Stack>
      </Stack>
    </Layout>
  )
}

export default NeuigkeitenHoerl
